import { PageType } from '@invideoio/web-shared/types/Analytics';
import { GetServerSidePropsContext } from 'next';
import React, { useContext, useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { DispatchContext, StateContext } from '../../store/store';

import AppLayout from '../../layouts/AppLayout';
import HomepageSEO from '@components/common/SEO/HomepageSEO';

import { Referrer } from '@invideoio/web-shared/utils/utils.constant';
import { registerAndSendPageViewEvent } from '@invideoio/web-shared/utils/analytics/Register';
import { AnalyticsUtils } from '@invideoio/web-shared/utils/analytics/AnalyticsUtils';
import { fetchTemplates, getHomepageGen3 } from '../../lib/API';
import {
  setCommonAnalyticsProperties,
  sendAmplitudeAnalyticEvent,
  AmplitudeEvents,
} from '@utils/analytics';
import HeaderWithCTA from '@components/header-ai';
import HomepageAI from '@layouts/HomepageAI';
import { HomepageResponseType, TemplateType } from '../../types';
import useLazyload from '@hooks/useLazyload';
import StudioBanner from '@invideoio/web-shared/components/StudioBanner';
import { setCacheControlHeaders } from '@utils/server.utils';
import { BaseAppType, CMSDimensionType } from '@constants/common';
import { baseAppTypeLoaded } from '@store/actions';
import Banner from './components/Banner';
import {
  attachTemplates,
  extractTemplateDimensions,
  extractTemplateIds,
} from '@utils/common.utils';
import { PlatformType } from '@invideoio/web-shared/constants/common';

interface AiWaitlistPageProps {
  pageData: HomepageResponseType;
}

function AiWaitList(props: AiWaitlistPageProps) {
  const { pageData } = props;
  const globalState = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const { lang } = useTranslation('home');
  const { platform, browser } = globalState;

  useLazyload();

  useEffect(() => {
    setCommonAnalyticsProperties();

    AnalyticsUtils.setReferrer(Referrer.WEBSITE);

    registerAndSendPageViewEvent(PageType.AI, {
      location: window.location.href,
    });

    sendAmplitudeAnalyticEvent(AmplitudeEvents.appLoad);
    dispatch(baseAppTypeLoaded(BaseAppType.AI));
  }, []);

  if (!pageData) {
    return null;
  }

  return (
    <AppLayout platform={platform}>
      <HomepageSEO seo={pageData.SEO} locale={lang} />
      {platform != PlatformType.Mobile && <StudioBanner browser={browser} />}
      {/* <Banner /> */}
      <HeaderWithCTA isCTAIntersected={true} pageType={PageType.HOMEPAGE} />

      <HomepageAI
        homepageState={pageData}
        showVolumeControls={false}
        isPreview={true}
      />
    </AppLayout>
  );
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  let pageData = null;

  let maxage = 3600;

  if (process.env.NEXT_PUBLIC_ENV !== 'production') {
    maxage = 300; // 5mins
  }

  setCacheControlHeaders(context, maxage);

  try {
    const homepageStrpiUrl = '/homepage-ai';
    pageData = await getHomepageGen3(homepageStrpiUrl, context.locale);

    if (
      pageData &&
      pageData.videoWallSlide_1 &&
      pageData.videoWallSlide_2 &&
      pageData.videoWallSlide_3 &&
      pageData.videoWallSlide_4
    ) {
      const ids = extractTemplateIds(
        pageData.videoWallSlide_1,
        pageData.videoWallSlide_2,
        pageData.videoWallSlide_3,
        pageData.videoWallSlide_4,
      );
      const dimensions: CMSDimensionType[] = extractTemplateDimensions(
        pageData.videoWallSlide_1,
        pageData.videoWallSlide_2,
        pageData.videoWallSlide_3,
        pageData.videoWallSlide_4,
      );
      const templates: TemplateType[] = await fetchTemplates(ids, dimensions);
      if (ids.length !== templates.length) {
        throw `Could not fetch few images from ${ids} got ${templates}`;
      }
      const attachedTemplates = attachTemplates({
        templateSlide1: pageData.videoWallSlide_1,
        templateSlide2: pageData.videoWallSlide_2,
        templateSlide3: pageData.videoWallSlide_3,
        templateSlide4: pageData.videoWallSlide_4,
        images: templates,
      });

      pageData = {
        ...pageData,
        videoWallSlide_1: attachedTemplates.templateSlide_1,
        videoWallSlide_2: attachedTemplates.templateSlide_2,
        videoWallSlide_3: attachedTemplates.templateSlide_3,
        videoWallSlide_4: attachedTemplates.templateSlide_4,
      };
    }

    return { props: { pageData } };
  } catch (error) {
    console.log(error);

    return {
      notFound: true,
    };
  }
}

export default AiWaitList;
